import React, { useRef } from "react";
import Avtar from "./../assets/images/img1.jpg";
import Send from "./../assets/svg/send.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import * as vidioActions from "../redux/vidio/vidioAction";
import { editPen } from "../assets/svg/svg";
import { toast } from "react-toastify";
import Loader from "../components/common/Loader";
import { Col, Dropdown, Row } from "react-bootstrap";
import Pagination from "../components/common/Pagination";
const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

export default function VideoComments() {
  const [comment, setComment] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [commentId, setCommentId] = useState("");
  const inputRef = useRef(null); // Create a ref for the input field

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const getAllVideoComments = useSelector((state) => state.vidioInfo.videoAllComments);
  const getAllVideoCommentsCount = useSelector((state) => state.vidioInfo.totalCommentCount);
  const loading = useSelector((state) => state.vidioInfo.loading);
  const commentPerPage = 10;

  // get all comments:
  useEffect(() => {
    if (params?.id) {
      dispatch(vidioActions.getAllComments(params.id, currentPage));
    }
  }, [params?._id, currentPage]);

  useEffect(() => {
    if (getAllVideoCommentsCount) {
      const totalPages = Math.ceil(getAllVideoCommentsCount / commentPerPage);
      setTotalPages(totalPages);
    }
  }, [getAllVideoCommentsCount]);

  const handleCommentOnVideo = async () => {
    if (!isEdit && comment) {
      await dispatch(vidioActions.commentOnVideo({ videoId: params.id, comment: comment }));
      dispatch(vidioActions.getAllComments(params.id));
      // setCurrentPage(1);
      setComment("");
    }
    if (isEdit && comment && commentId) {
      await dispatch(vidioActions.updateVideoComment(commentId, { comment: comment }));
      dispatch(vidioActions.getAllComments(params.id, currentPage));
      setComment("");
      setIsEdit(false);
    }
  };

  // const handlegetTimeFrom = (timestamp) => {
  //   const now = moment();
  //   const diff = moment.duration(now.diff(timestamp));

  //   if (diff.asSeconds() < 60) {
  //     return `${Math.round(diff.asSeconds())} seconds ago`;
  //   } else if (diff.asMinutes() < 60) {
  //     return `${Math.round(diff.asMinutes())} minutes ago`;
  //   } else if (diff.asHours() < 24) {
  //     return `${Math.round(diff.asHours())} hours ago`;
  //   } else {
  //     return `${Math.round(diff.asDays())} days ago`;
  //   }
  // };

  const handlegetTimeFrom = (timestamp) => {
    const now = moment();
    const diff = moment.duration(now.diff(timestamp));
  
    if (diff.asSeconds() < 0) {
      return "just now";
    }
  
    if (diff.asSeconds() < 60) {
      return `${Math.round(diff.asSeconds())} seconds ago`;
    } else if (diff.asMinutes() < 60) {
      return `${Math.round(diff.asMinutes())} minutes ago`;
    } else if (diff.asHours() < 24) {
      return `${Math.round(diff.asHours())} hours ago`;
    } else {
      return `${Math.round(diff.asDays())} days ago`;
    }
  };
  
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleCommentOnVideo();
    }
  };

  const handleEditClick = (c) => {
    setCommentId(c?._id);
    setIsEdit(true);
    setComment(c?.comment);
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  };

  const deleteVideoComment = async (c) => {
    await dispatch(vidioActions.deleteVideoComment(c._id));
    dispatch(vidioActions.getAllComments(params.id, currentPage));
  };

  return (
    <section className="comment_container">
      <div className="container">
        {loading ? (
          <Loader height={"30vh"} />
        ) : getAllVideoComments.length ? (
          getAllVideoComments.map((c) => (
            <div className="comment_wrapper">
              <div className="avtar">
                <img src={c?.userDetails?.image ? IMAGE_BASE_URL + c?.userDetails?.image : Avtar} alt="" />
              </div>
              <div className="content_wrapper">
                <div className="top">
                  <div className="name">{c?.userDetails?.name}</div>
                  <div className="time">{handlegetTimeFrom(c?.createdAt)}</div>
                </div>
                <div className="bottom">{c?.comment} </div>
              </div>

              {/* {c?.isEditable && (
                <button
                  className="edit_pen_button comment_edit_button"
                  onClick={() => {
                    handleEditClick(c);
                  }}
                >
                  {editPen}
                </button>
              )} */}

              {c?.isEditable ? (
                <Dropdown className="comment_edit_button">
                  <Dropdown.Toggle
                    variant="link"
                    style={{ textDecoration: "none", color: "#7E879F" }}
                    className="three-dots-button"
                  >
                    &#8942;
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        handleEditClick(c);
                      }}
                    >
                      {editPen} Edit
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => deleteVideoComment(c)}>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Dropdown className="comment_edit_button">
                  <Dropdown.Toggle
                    variant="link"
                    style={{ textDecoration: "none", color: "#7E879F" }}
                    className="three-dots-button"
                  ></Dropdown.Toggle>
                </Dropdown>
              )}
            </div>
          ))
        ) : (
          <div className="comment_wrapper">
            <h5 style={{ width: "100%", textAlign: "center", fontWeight: 600 }}>No comments found</h5>
          </div>
        )}
        {totalPages > 1 && (
          <Row className="mt-3 mb-4 pt-3">
            <Col className="d-flex justify-content-end">
              <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
            </Col>
          </Row>
        )}
        <div className="add_comment"  ref={inputRef}>
          <input
            type="text"
            placeholder="Enter comment"
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            onKeyDown={handleKeyPress}
          />
          <img src={Send} onClick={handleCommentOnVideo} alt="send" />
        </div>
      </div>
    </section>
  );
}
