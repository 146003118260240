import React, { useEffect, useRef, useState } from "react";
import Avtar from "../../assets/images/img1.jpg";
import Send from "../../assets/svg/send.svg";
import { useParams } from "react-router-dom";
import * as blogActions from "../../redux/blog/blogAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { editPen } from "../../assets/svg/svg";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
import { Col, Dropdown, Row } from "react-bootstrap";
import Pagination from "../../components/common/Pagination";

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

export default function BlogComments() {
  const [comment, setComment] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const inputRef = useRef(null); // Create a ref for the input field

  const params = useParams();
  const dispatch = useDispatch();
  const blogsComment = useSelector((state) => state.blogInfo.allBlogComments);
  const blogCommentCount = useSelector((state) => state.blogInfo.commentCount);

  const isSendComment = useSelector((state) => state.blogInfo.sendComment);
  const loading = useSelector((state) => state.blogInfo.loading);
  const commentPerPage = 10;

  useEffect(() => {
    if (params?.id) {
      dispatch(blogActions.getAllBlogsComment(params.id, currentPage));
    }
  }, [params?._id, currentPage]);

  useEffect(() => {
    if (blogCommentCount) {
      const totalPages = Math.ceil(blogCommentCount / commentPerPage);
      setTotalPages(totalPages);
    }
  }, [blogCommentCount]);

  const handleCommentOnBlog = async () => {
    if (!isEdit && comment) {
      await dispatch(blogActions.commentOnBlog({ blogId: params.id, comment: comment }));
      dispatch(blogActions.getAllBlogsComment(params.id));
      setComment("");
      setCurrentPage(1);
    }
    if (isEdit && comment && commentId) {
      await dispatch(blogActions.updateCommentOnBlog(commentId, { comment }));
      dispatch(blogActions.getAllBlogsComment(params.id, currentPage));
      setIsEdit(false);
      setComment("");
    }
  };

  const handlegetTimeFrom = (timestamp) => {
    const now = moment();
    const diff = moment.duration(now.diff(timestamp));
  
    if (diff.asSeconds() < 0) {
      return "just now";
    }
  
    if (diff.asSeconds() < 60) {
      return `${Math.round(diff.asSeconds())} seconds ago`;
    } else if (diff.asMinutes() < 60) {
      return `${Math.round(diff.asMinutes())} minutes ago`;
    } else if (diff.asHours() < 24) {
      return `${Math.round(diff.asHours())} hours ago`;
    } else {
      return `${Math.round(diff.asDays())} days ago`;
    }
  };
  

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleCommentOnBlog();
    }
  };

  const handleEditClick = (c) => {
    setIsEdit(true);
    setCommentId(c?._id);
    setComment(c?.comment);
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const deleteBlogComment = async (c) => {
    // delete logic
    await dispatch(blogActions.deleteBlogComment(c?._id));
    dispatch(blogActions.getAllBlogsComment(params.id, currentPage));
  };
  return (
    <section className="comment_container">
      <div className="container">
        {loading ? (
          <Loader height={"30vh"} />
        ) : blogsComment.length ? (
          blogsComment.map((c) => (
            <div className="comment_wrapper">
              <div className="avtar">
                <img src={c?.userDetails?.image ? IMAGE_BASE_URL + c?.userDetails?.image : Avtar} alt="" />
              </div>
              <div className="content_wrapper">
                <div className="top">
                  <div className="name">{c?.userDetails?.name}</div>
                  <div className="time">{handlegetTimeFrom(c?.createdAt)}</div>
                </div>
                <div className="bottom">{c?.comment} </div>
              </div>
              {/* <div className="manage_address_action"> */}
              {/* {c?.isEditable && (
                <button
                  className="edit_pen_button comment_edit_button"
                  onClick={() => {
                    handleEditClick(c);
                  }}
                >
                  {editPen}
                </button>
              )} */}

              {c?.isEditable ? (
                <Dropdown className="comment_edit_button">
                  <Dropdown.Toggle
                    variant="link"
                    style={{ textDecoration: "none", color: "#7E879F" }}
                    className="three-dots-button"
                  >
                    &#8942;
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        handleEditClick(c);
                      }}
                    >
                      {editPen} Edit
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => deleteBlogComment(c)}>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Dropdown className="comment_edit_button">
                  <Dropdown.Toggle
                    variant="link"
                    style={{ textDecoration: "none", color: "#7E879F" }}
                    className="three-dots-button"
                  ></Dropdown.Toggle>
                </Dropdown>
              )}
              {/* </div> */}
            </div>
          ))
        ) : (
          <div className="comment_wrapper">
            <h5 style={{ width: "100%", textAlign: "center", fontWeight: 600 }}>No comments found</h5>
          </div>
        )}
        {/* <div className="comment_wrapper">
          <div className="avtar">
            <img src={Avtar} alt="" />
          </div>
          <div className="content_wrapper">
            <div className="top">
              <div className="name">Harry T. Cross</div>
              <div className="time">1 hr ago</div>
            </div>
            <div className="bottom">
              It is a long established fact that a reader will be distracted by the readable content.
            </div>
          </div>
        </div>
        <div className="comment_wrapper">
          <div className="avtar">
            <img src={Avtar} alt="" />
          </div>
          <div className="content_wrapper">
            <div className="top">
              <div className="name">Harry T. Cross</div>
              <div className="time">1 hr ago</div>
            </div>
            <div className="bottom">
              It is a long established fact that a reader will be distracted by the readable content.
            </div>
          </div>
        </div> */}
        {totalPages > 1 && (
          <Row className="mt-3 mb-4 pt-3">
            <Col className="d-flex justify-content-end">
              <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
            </Col>
          </Row>
        )}
        <div className="add_comment"  ref={inputRef}>
          <input
            type="text"
            placeholder="Enter comment"
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            onKeyDown={handleKeyPress}
          />
          <img src={Send} onClick={handleCommentOnBlog} alt="send" />
        </div>
      </div>
    </section>
  );
}
