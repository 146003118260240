import * as reminderActions from "./reminderAction";
export const reminderFeatureKey = "reminderInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  reminderProducts: [],
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case reminderActions.GET_ALL_REMINDER_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case reminderActions.GET_ALL_REMINDER_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reminderProducts: payload.data,
      };
    case reminderActions.GET_ALL_REMINDER_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
