import { combineReducers } from "redux";
import * as userReducers from "./user/userReducer";
import * as addressReducer from "./address/addressReducer";
import * as homeReducer from "./home/homeReducer";
import * as petCategoryReducer from "./petCategory/petCategoryReducer";
import * as vetDropdownReducer from "./vetDropdowns/vetDropdownReducer";
import * as consultationReducer from "./consultation/consultationReducer";
import * as vetReducer from "./vet/vetReducer";
import * as petReducer from "./pet/petReducer";
import * as homeCareReducer from "./homecare/homeCareReducer";
import * as pharmacyReducer from "./pharmacy/pharmacyReducer";
import * as vidioReducer from "./vidio/vidioReducer";
import * as productReducer from "./product/productReducer";
import * as cartReducer from "./cart/cartReducer";
import * as orderReducer from "./order/orderReducer";
import * as ratingReducer from "./rating/ratingReducer";
import * as blogReducer from "./blog/blogReducer";
import * as petReportAbuseReducer from "./petReportAbuse/petReportAbuseReducer";
import * as prescriptionReducer from "./prescription/prescriptionReducer";
import * as phonepePaymentReducer from "./phonepePayment/phonepePaymentReducer";
import * as couponCodeReducer from "./couponCode/couponCodeReducer";
import * as reminderReducer from "./reminder/reminderReducer";
import * as referReducer from "./refer/referReducer";

export const rootReducer = combineReducers({
  [userReducers.userFeatureKey]: userReducers.reducer,
  [addressReducer.addressFeatureKey]: addressReducer.reducer,
  [homeReducer.homeFeatureKey]: homeReducer.reducer,
  [petCategoryReducer.petCategoryFeatureKey]: petCategoryReducer.reducer,
  [vetDropdownReducer.vetDropdownFeatureKey]: vetDropdownReducer.reducer,
  [consultationReducer.consultationFeatureKey]: consultationReducer.reducer,
  [vetReducer.vetFeatureKey]: vetReducer.reducer,
  [petReducer.petFeatureKey]: petReducer.reducer,
  [homeCareReducer.homeCareFeatureKey]: homeCareReducer.reducer,
  [pharmacyReducer.pharmacyFeatureKey]: pharmacyReducer.reducer,
  [vidioReducer.vidioFeatureKey]: vidioReducer.reducer,
  [productReducer.productFeatureKey]: productReducer.reducer,
  [cartReducer.cartFeatureKey]: cartReducer.reducer,
  [orderReducer.orderFeatureKey]: orderReducer.reducer,
  [ratingReducer.ratingFeatureKey]: ratingReducer.reducer,
  [petReportAbuseReducer.petReportAbuseFeatureKey]: petReportAbuseReducer.reducer,
  [blogReducer.blogFeatureKey]: blogReducer.reducer,
  [prescriptionReducer.prescriptionFeatureKey]: prescriptionReducer.reducer,
  [phonepePaymentReducer.phonepePaymentFeatureKey]: phonepePaymentReducer.reducer,
  [couponCodeReducer.couponFeatureKey]: couponCodeReducer.reducer,
  [reminderReducer.reminderFeatureKey]: reminderReducer.reducer,
  [referReducer.referFeatureKey]: referReducer.reducer,
});
