import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_ALL_BLOGS_REQUEST = "GET_ALL_BLOGS_REQUEST";
export const GET_ALL_BLOGS_SUCCESS = "GET_ALL_BLOGS_SUCCESS";
export const GET_ALL_BLOGS_FAILURE = "GET_ALL_BLOGS_FAILURE";

// Upadte views:
export const UPDATE_BLOG_VIEWS_REQUEST = "UPDATE_BLOG_VIEWS_REQUEST";
export const UPDATE_BLOG_VIEWS_SUCCESS = "UPDATE_BLOG_VIEWS_SUCCESS";
export const UPDATE_BLOG_VIEWS_FAILURE = "UPDATE_BLOG_VIEWS_FAILURE";

// Blog like:
export const BLOG_LIKE_REQUEST = "BLOG_LIKE_REQUEST";
export const BLOG_LIKE_SUCCESS = "BLOG_LIKE_SUCCESS";
export const BLOG_LIKE_FAILURE = "BLOG_LIKE_FAILURE";

// Get all blogs comment:
export const GET_ALL_BLOG_COMMENTS_REQUEST = "GET_ALL_BLOG_COMMENTS_REQUEST";
export const GET_ALL_BLOG_COMMENTS_SUCCESS = "GET_ALL_BLOG_COMMENTS_SUCCESS";
export const GET_ALL_BLOG_COMMENTS_FAILURE = "GET_ALL_BLOG_COMMENTS_FAILURE";

// Comment on blog
export const COMMENT_ON_BLOG_REQUEST = "COMMENT_ON_BLOG_REQUEST";
export const COMMENT_ON_BLOG_SUCCESS = "COMMENT_ON_BLOG_SUCCESS";
export const COMMENT_ON_BLOG_FAILURE = "COMMENT_ON_BLOG_FAILURE";

// update comment
export const UPDATE_COMMENT_ON_BLOG_REQUEST = "UPDATE_COMMENT_ON_BLOG_REQUEST";
export const UPDATE_COMMENT_ON_BLOG_SUCCESS = "UPDATE_COMMENT_ON_BLOG_SUCCESS";
export const UPDATE_COMMENT_ON_BLOG_FAILURE = "UPDATE_COMMENT_ON_BLOG_FAILURE";


// delete comment 
export const DELETE_BLOG_COMMENT_REQUEST = "DELETE_BLOG_COMMENT_REQUEST";
export const DELETE_BLOG_COMMENT_SUCCESS = "DELETE_BLOG_COMMENT_SUCCESS";
export const DELETE_BLOG_COMMENT_FAILURE = "DELETE_BLOG_COMMENT_FAILURE";

export const getAllBlogs = (page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_BLOGS_REQUEST });
      const url = `${BASE_URL}/user/get/all/blog/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });
      dispatch({ type: GET_ALL_BLOGS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_ALL_BLOGS_FAILURE, payload: error.message });
    }
  };
};

export const updateBlogViews = (b) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_BLOG_VIEWS_REQUEST });
      const url = `${BASE_URL}/user/update/blog/views/${b?._id}/v1`;
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: UPDATE_BLOG_VIEWS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: UPDATE_BLOG_VIEWS_FAILURE, payload: error.message });
    }
  };
};

export const likeBlog = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BLOG_LIKE_REQUEST });
      const url = `${BASE_URL}/blog/like/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: BLOG_LIKE_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: BLOG_LIKE_FAILURE, payload: error.message });
    }
  };
};

export const getAllBlogsComment = (blogId, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_BLOG_COMMENTS_REQUEST });
      const url = `${BASE_URL}/blog/get/all/comments/${blogId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page,
        },
      });
      dispatch({ type: GET_ALL_BLOG_COMMENTS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_ALL_BLOG_COMMENTS_FAILURE, payload: error.message });
    }
  };
};

export const commentOnBlog = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: COMMENT_ON_BLOG_REQUEST });
      const url = `${BASE_URL}/blog/comment/to/blog/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: COMMENT_ON_BLOG_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: COMMENT_ON_BLOG_FAILURE, payload: error.message });
    }
  };
};

export const updateCommentOnBlog = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_COMMENT_ON_BLOG_REQUEST });
      const url = `${BASE_URL}/blog/update/comment/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_COMMENT_ON_BLOG_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: UPDATE_COMMENT_ON_BLOG_FAILURE, payload: error.message });
    }
  };
};


export const deleteBlogComment = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_BLOG_COMMENT_REQUEST });
      const url = `${BASE_URL}/blog/delete/comment/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_BLOG_COMMENT_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: DELETE_BLOG_COMMENT_FAILURE, payload: error.message });
    }
  };
};