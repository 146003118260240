import axios from "axios";
import { toast } from "react-toastify";
import { userFeatureKey } from "./userReducer";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const RECEIVED_PUSH_NOTIFICATION_SUCCESS = "RECEIVED_PUSH_NOTIFICATION_SUCCESS";

export const STORE_MARKETING_FCM_REQUEST = "STORE_MARKETING_FCM_REQUEST";
export const STORE_MARKETING_FCM_SUCCESS = "STORE_MARKETING_FCM_SUCCESS";
export const STORE_MARKETING_FCM_FAILURE = "STORE_MARKETING_FCM_FAILURE";

// login through google
export const GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAILURE = "GOOGLE_LOGIN_FAILURE";

// logout user
export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";

// login through mobile
export const MOBILE_NUMBER_LOGIN_REQUEST = "MOBILE_NUMBER_LOGIN_REQUEST";
export const MOBILE_NUMBER_LOGIN_SUCCESS = "MOBILE_NUMBER_LOGIN_SUCCESS";
export const MOBILE_NUMBER_LOGIN_FAILURE = "MOBILE_NUMBER_LOGIN_FAILURE";

//send otp on email for profile update
export const SEND_EMAIL_OTP_REQUEST = "SEND_EMAIL_OTP_REQUEST";
export const SEND_EMAIL_OTP_SUCCESS = "SEND_EMAIL_OTP_SUCCESS";
export const SEND_EMAIL_OTP_FAILURE = "SEND_EMAIL_OTP_FAILURE";

// Verify email otp:
export const VERIFY_EMAIL_OTP_REQUEST = "VERIFY_EMAIL_OTP_REQUEST";
export const VERIFY_EMAIL_OTP_SUCCESS = "VERIFY_EMAIL_OTP_SUCCESS";
export const VERIFY_EMAIL_OTP_FAILURE = "VERIFY_EMAIL_OTP_FAILURE";

// user profile
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

// update user
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

// Notification history
export const GET_USER_NITIFICATIONS_REQUEST = "GET_USER_NITIFICATIONS_REQUEST";
export const GET_USER_NITIFICATIONS_SUCCESS = "GET_USER_NITIFICATIONS_SUCCESS";
export const GET_USER_NITIFICATIONS_FAILURE = "GET_USER_NITIFICATIONS_FAILURE";

// Read notifications:
export const UPDATE_USER_NITIFICATIONS_REQUEST = "UPDATE_USER_NITIFICATIONS_REQUEST";
export const UPDATE_USER_NITIFICATIONS_SUCCESS = "UPDATE_USER_NITIFICATIONS_SUCCESS";
export const UPDATE_USER_NITIFICATIONS_FAILURE = "UPDATE_USER_NITIFICATIONS_FAILURE";
export const receivedNotification = (data) => {
  return async (dispatch) => {
    dispatch({ type: RECEIVED_PUSH_NOTIFICATION_SUCCESS, payload: data });
  };
};

export const storeMarketingFcm = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STORE_MARKETING_FCM_REQUEST });
      const url = `${BASE_URL}/auth/create/marketing/fcm/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: STORE_MARKETING_FCM_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: STORE_MARKETING_FCM_FAILURE, payload: error.message });
    }
  };
};

export const logoutUser = (deviceId, fcmToken) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGOUT_USER_REQUEST });
      const url = `${BASE_URL}/auth/logout/user/v1`;
      const response = await axios.post(
        url,
        { deviceId, fcmToken },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: LOGOUT_USER_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGOUT_USER_FAILURE, payload: error.message });
    }
  };
};

export const googleLogin = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GOOGLE_LOGIN_REQUEST });
      const url = `${BASE_URL}/auth/login/user/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: GOOGLE_LOGIN_SUCCESS, payload: response.data });
      toast.success("You have successfully logged in.");
      if (response?.data?.data?.isNewUser) {
        navigate("/refer-code");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message);
      dispatch({ type: GOOGLE_LOGIN_FAILURE, payload: error.message });
    }
  };
};

export const mobileNumberLogin = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: MOBILE_NUMBER_LOGIN_REQUEST });
      const url = `${BASE_URL}/auth/login/user/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: MOBILE_NUMBER_LOGIN_SUCCESS, payload: response.data });
      if (response?.data?.data?.isNewUser) {
        navigate("/refer-code");
      } else {
        navigate("/");
      }
      toast.success("Login Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: MOBILE_NUMBER_LOGIN_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

// send otp on email
export const sendOtpOnEmailForProfileUpdate = (setOtpPopUpForEmail, formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SEND_EMAIL_OTP_REQUEST });
      const url = `${BASE_URL}/user/send/otp/via/email/v1`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: SEND_EMAIL_OTP_SUCCESS, payload: response.data });
      setOtpPopUpForEmail(true);
      // setEmailOtp(response?.data?.data);
      toast.success("Otp is send on email successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: SEND_EMAIL_OTP_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const verifyEmailOtpForUserProfile = (
  formData,
  setOtpPopUpForEmail,
  handleClose,
  setIsEmailVerified,
  setCode
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VERIFY_EMAIL_OTP_REQUEST });
      const url = `${BASE_URL}/user/verify/otp/v1`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: VERIFY_EMAIL_OTP_SUCCESS, payload: response.data });
      setOtpPopUpForEmail(false);
      handleClose();
      setIsEmailVerified(true);
      setCode("");
      // setEmailOtp(response?.data?.data);
      toast.success("Email verified successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: VERIFY_EMAIL_OTP_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};
// get User Profile
export const getUser = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_REQUEST });
      const url = `${BASE_URL}/user/get/my/profile/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_USER_SUCCESS, payload: response.data });
      // toast.success("My Profile", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 500 || error?.response?.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      dispatch({ type: GET_USER_FAILURE, payload: error.message });
      // toast.error(error?.response?.data?.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    }
  };
};

// update User Profile
export const updateUser = (data, user) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_REQUEST });
      const url = `${BASE_URL}/user/update/my/profile/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_USER_SUCCESS, payload: response.data });
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_USER_FAILURE, payload: error.message });
      toast.success(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const myAllNotifications = ({ page }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_NITIFICATIONS_REQUEST });
      const url = `${BASE_URL}/fcm/get/all/my/fcm/noti/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: { page },
      });
      dispatch({ type: GET_USER_NITIFICATIONS_SUCCESS, payload: response.data });
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_USER_NITIFICATIONS_FAILURE, payload: error.message });
      toast.success(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const userReadNotifications = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_NITIFICATIONS_REQUEST });
      const url = `${BASE_URL}/fcm/read/notifications/by/user/v1`;
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: UPDATE_USER_NITIFICATIONS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_USER_NITIFICATIONS_FAILURE, payload: error.message });
    }
  };
};
